export interface ThemeSettings {
    accent_color: string;
    background_color: string;
    categories_layout: 'dropdown' | 'bar';
    font: 'AG Geogrotesque';
    footer_background_color: string;
    footer_text_color: string;
    full_width_featured_story: boolean;
    header_background_color: string;
    header_image_placement: 'above' | 'below';
    header_link_color: string;
    layout: 'grid' | 'masonry';
    logo_size: string;
    main_logo: string | null;
    main_site_url: string | null;
    palette: Record<string, Record<string, `#${string}`>>; // Specific to AG Custom Theme
    show_date: boolean;
    show_featured_categories: boolean;
    show_sharing_icons: boolean;
    show_subtitle: boolean;
    story_card_variant: 'default' | 'boxed';
    text_color: string;
}

export const DEFAULT_THEME_SETTINGS: ThemeSettings = {
    accent_color: '#3b82f6',
    background_color: '#ffffff',
    categories_layout: 'dropdown',
    font: 'AG Geogrotesque',
    footer_background_color: '#111827',
    footer_text_color: '#ffffff',
    full_width_featured_story: false,
    header_background_color: '#ffffff',
    header_image_placement: 'below',
    header_link_color: '#4b5563',
    layout: 'grid',
    logo_size: 'medium',
    main_logo: null,
    main_site_url: null,
    palette: {}, // Specific to AG Custom Theme
    show_date: true,
    show_featured_categories: true,
    show_sharing_icons: true,
    show_subtitle: false,
    story_card_variant: 'default',
    text_color: '#374151',
};
